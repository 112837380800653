import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@atoms/Link"
import { Image } from "@atoms/Image"
// import { Section, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-special.module.styl"

export default function IndexSpecial()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexSpecial__lbl__title.svg" }) {
                    publicURL
            }
            catch : file(relativePath: { eq: "IndexSpecial__lbl__catch1.svg" }) {
                    publicURL
            }
            img01 : file(relativePath: { eq: "IndexSpecial__img__01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img02 : file(relativePath: { eq: "IndexSpecial__img__02.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img03 : file(relativePath: { eq: "IndexSpecial__img__03.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img04 : file(relativePath: { eq: "IndexSpecial__img__04.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    return(
        <section className={css.pIndexSpecial} id="special">
            <div className={css.pIndexSpecial__inner}>
                <h2 className={css.pIndexSpecial__title}>
                    <img src={q.title.publicURL} alt="SPECIAL1 ENTERTAINMENT ACADEMY - スペシャルワン・エンターテインメント・アカデミー -"/>
                </h2>
                <figure className={css.pIndexSpecial__imgMax}>
                    <Image data={q.img01.childImageSharp}/>
                    <figcaption>
                        2018.12.08 SAT WONDER&CLOCKS presents<br/>
                        P.O.W.E.R. FESTIVAL feat. DEAN
                    </figcaption>
                </figure>
                <figure className={css.pIndexSpecial__imgMax}>
                    <Image data={q.img02.childImageSharp}/>
                    <figcaption>
                        2022.6.19 SAT<br/>
                        SPECIAL1 ENTERTAINMENT ACADEMY SHOWCASE<br/>
                        @ SOUND MUSEUM VISION 渋谷
                    </figcaption>
                </figure>
                <div className={css.pIndexSpecial__paragraph}>
                    <img src={q.catch.publicURL} alt="アジア・世界に向けてSPECIAL1を。May the Dream be with you 想像力と行動力 = 夢" />
                    <p>
                        これまでDEAN、GRAY（AOMG）DPRLIVEなど韓国のトップアーティストをはじめ、マーク・ロンソンやカルヴィン・ハリス等、世界中のトップアーティストのショーを成功させた
                        <Link to="#">WONDER＆CLOCKS（ワンクロ）</Link>がアカデミーを開校。
                    </p>
                    <p>
                        K-POPの本場、韓国メソッドによる少数精鋭のスクール、その名も『SPECIAL1 ENTERTAINMENT ACADEMY』。
                    </p>
                    <h3 className="strong-1">
                        <span>練習生の中から選ばれた人は</span><span>オリジナル楽曲をリリース</span>
                    </h3>
                    <figure>
                        <Image data={q.img03.childImageSharp}/>
                    </figure>
                    <figure>
                        <Image data={q.img04.childImageSharp}/>
                    </figure>
                    <p>
                        東京・中目黒のスタジオに一流の指導陣が集結。1年後に本アカデミー練習生の中から、世界・韓国に向けてオリジナル楽曲をリリースすることを約束します。
                    </p>
                    <h3 className="strong-2">
                        <span>さあ、</span>
                        <span>次の「SPECIAL1」は、</span>
                        <span >キミだ。</span>
                    </h3>
                </div>
            </div>
        </section>
    )
}
