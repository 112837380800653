import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
import { Section, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-workshop.module.styl"

export default function IndexWorkshop()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexWorkshop__lbl__title.svg" }) {
                    publicURL
            }
            img01 : file(relativePath: { eq: "IndexWorkshop__img__01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img02 : file(relativePath: { eq: "IndexWorkshop__img__02.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            img03 : file(relativePath: { eq: "IndexWorkshop__img__03.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    return(
        <section className={css.pIndexLessons} id="workshop">
            <div className={css.pIndexLessons__inner}>
                <h2 className={css.pIndexLessons__title}>
                    <img src={q.title.publicURL} alt="ワークショップ&スペシャルレッスン - Workshop＆Special lesson -" />
                </h2>
                <p className={css.pIndexLessons__paragraph}>
                    <p>その他に、エンターテインメント各界からの著名人をお招きした特別なワークショップの開催予定しています。※別途チケット制</p>
                </p>
                <figure className={css.pIndexLessons__img}>
                    <Image data={q.img01.childImageSharp}/>
                </figure>
                <figure className={css.pIndexLessons__img}>
                    <Image data={q.img02.childImageSharp}/>
                </figure>
                <figure className={css.pIndexLessons__img}>
                    <Image data={q.img03.childImageSharp}/>
                </figure>
            </div>
        </section>
    )
}
