import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
// import { Section, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-access.module.styl"

export default function IndexAccess()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexAccess__lbl__title.svg" }) {
                    publicURL
            }
            img01 : file(relativePath: { eq: "IndexAccess__img__01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    return(
        <section id="access" className={css.pIndexAccess}>
            <div className={css.pIndexAccess__inner}>
                <h2 className={css.pIndexAccess__title}>
                    <img src={q.title.publicURL} alt="代表からのメッセージ - MESSAGE FROM CEO -"/>
                </h2>
                <figure className={css.pIndexAccess__map}>
                    <Image data={q.img01.childImageSharp} alt=""/>
                </figure>
                <p className={css.pIndexAccess__address}>
                    東京都目黒区上目黒2-9-35 中目黒GS第2ビル B1<br/>ワンクロ中目黒エンターテイメント スタジオ
                </p>
                <p className={css.pIndexAccess__text}>
                    東急東横線・日比谷線中目黒駅より徒歩約90秒。GTビルすぐ横。駅近で通学にも大変便利な立地です。
                </p>
                <div className={css.pIndexAccess__footer}>
                    <Link className="button" to="https://goo.gl/maps/BpiyeFBA7XNmaD4C9" blank>Googleマップで見る</Link>
                </div>
            </div>
        </section>
    )
}
