// extracted by mini-css-extract-plugin
export var pIndexInstructors = "sp1ja";
export var pIndexInstructors__inner = "sp1ka";
export var pIndexInstructors__inner2 = "sp1la";
export var pIndexInstructors__title = "sp1ma";
export var pIndexInstructors__subtitle = "sp1na";
export var pIndexInstructors__imgMax = "sp1oa";
export var pIndexInstructors__paragraph = "sp1pa";
export var pIndexInstructors__textSmall = "sp1qa";
export var pIndexInstructors__list = "sp1ra";
export var pIndexInstructors__list__inner = "sp1sa";
export var animationMarquee = "sp1ta";