import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import { Link } from "@atoms/Link"

import * as css from "@css/projects/p-index-instructors.module.styl"

export default function IndexInstructors()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexInstructors__lbl__title.svg" }) {
                    publicURL
            }
            roydo : file(relativePath: { eq: "IndexInstructors__img__roydo.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            longDrive : file(relativePath: { eq: "IndexInstructors__img__long-drive.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            kondo : file(relativePath: { eq: "IndexInstructors__img__kondo.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            hana : file(relativePath: { eq: "IndexInstructors__img__hana.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            kajitsu : file(relativePath: { eq: "IndexInstructors__img__kajitsu.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            soYonhun : file(relativePath: { eq: "IndexInstructors__img__so-yonhun.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            jondehyon : file(relativePath: { eq: "IndexInstructors__img__jondehyon.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            masuzima : file(relativePath: { eq: "IndexInstructors__img__masuzima.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            mizuka : file(relativePath: { eq: "IndexInstructors__img__mizuka.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }


        }
    `)
    let list = [
        {
            img: q.roydo,
            name: 'ROYDO (From Seoul)',
            pos: 'プロデューサー/シンガーソングライター/ボーカルディレクター',
            text: `
<p>ソウル出身。韓国在住。Wanna One「<a href="https://www.youtube.com/watch?v=pmMjkMtpnTc" target="_blank">BOOMERANG</a>」THE BOYZ「<a href="https://www.youtube.com/watch?v=8n6pRkpVsyU" target="_blank">No Air</a>」等の大ヒット曲を世に送り出した、PENTAGON、B.A.P等多くの韓国トップアーティストの楽曲を手がける韓国随一のプロデューサー・シンガーソングライター。</p>
<p>BLACKPINKが所属するYG EntertainmentではTreasureのメンバー、Brand New Music所属のAb6ix等でボーカルトレーナーの経験を持つ。</p>`,
            url: '#',
            content: `
<h5>
プロデュース楽曲(作詞、作曲)
</h5>
<p>
Wanna One - Boomerang<br/>
THE BOYZ - No Air, L.O.U<br/>
MXM(Brand New Boys) - YA YA YA<br/>
PENTAGON - Lucky<br/>
B.A.P - 걸어가<br/>
UNB - 감각<br/>
OnlyOneOf - Blossom, 너 거짓말 나<br/>
THE UNI+ - All Day<br/>
멋진녀석들 - Be On You, Run, Black and White, R.O.M.L(너 나 우리), Dang<br/>
A-JAX - 능구렁이<br/>
ToppDogg - 귀여운걸<br/>
정재욱 - 새로고침(F5)<br/>
차오루, 수웅 - Rock Around On My Mind
</p>
<h5>ボーカルトレーニング実績</h5>
<p>
YG Ent. 練習生専門ボーカルトレーナー<br/>
YG Ent. Treasureメンバー パクジフン、ユンジェヒョク、アサヒ トレーニング<br/>
Brand New Music Ab6ixメンバー ジョンウン トレーニング<br/>
C9 Ent. CIXメンバー キムスンフン トレーニング<br/>
Brand New Music Bdc ユンジョンファン<br/>
J-FLO ENTERTAINMENT Newkiddメンバー チハンソル トレーニング<br/>
Oui Entertainment WEiメンバー カンソクファ トレーニング
</p>

<h5>ROYDO Release 楽曲</h5>
<p>
[Single] Shining Star (2013)<br/>
[Single] Oh Nice (2014)<br/>
[Single] Look Around (2014)<br/>
[Single] Gimme Somethin (2015)<br/>
[EP] ROYDO MUSIC (2015)<br/>
[Single] I Need U (2016)<br/>
[Single] About You (2016)<br/>
[Single] 예전엔 (Rewind) (2017)<br/>
[Single] FACE (Feat. pH-1) (2017)<br/>
[LP] ROYDO (2018)<br/>
[Single] Lonely (2019)<br/>
[EP] Universe (2019）<br/>
</p>
`,
        }, {
            img: q.hana,
            name: 'hana (That crew)',
            pos: 'ダンスディレクター',
            text: `
<p>幼少期からダンスをはじめ、JAZZ、ballet、HIPHOPなど あらゆるジャンルを習得。また幼少期からキッズダンサーとして 数々のショー、コンテストで優勝を飾る。</p>
<p>K-POPアイドルTWICEのmomoの実姉と知られるが、hanaは小柄な身体からは想像できない“力強くも女性らしいしなやかなダンス”と“独自の世界観”を武器に活躍するコレオグラファーである。</p>`,
            content: `
<h5>経歴</h5>
<p>
タマホーム CM 出演<br/>
2004年 岸本早未 "素敵な夢見ようね" PV出演<br/>
2005年 韓国 "b-boyに恋するバレリーナ" ゲスト出演<br/>
2006年 EZ DO DANCE キッズダンスコンテスト 優勝<br/>
2007年 USJ クリスマスライブ BoA バックダンサー出演<br/>
2009年 キラットエンタメチャレンジコンテスト 準優勝<br/>
2009年 全国スーパーキッズダンスコンテスト西日本大会 優勝<br/>
a-nation キッズネーション 2004年.2006年.2008年.2009年 出演<br/>
鈴木亜美 a-nation バックダンサー出演<br/>
2011年 韓国 super star K ファイナリスト テレビ出演<br/>
2014年 legend tokyo hassanさんnumber 出演<br/>
2015年 東方神起tour WITH  サポートダンサー<br/>
2016年、2017年 関西コレクション 振り付け・出演<br/>
2016年 IZUMI company ダンスコンテスト 準優勝<br/>
2016年 テレビ大阪 ”The choreographer” テレビ出演<br/>
2017年 音色 AISHA 振り付け・出演<br/>
2018年 LovRAVE "Love Line" 振り付け<br/>
2018年 Tae ワンマンライブ "オモイドーリ" 振り付け<br/>
2018年 橋本裕太 "恋人になれたら" 振り付け・PV出演<br/>
2019年 尼フェス Jasmine 振り付け・出演<br/>
2019年 アベマTV "恋愛ドラマな恋がしたい" 出演<br/>
2020年 日テレ マツコ会議 出演
</p>
`,
        }, {
            img: q.longDrive,
            name: 'LONG DRIVE (From Seoul)',
            pos: 'プロデューサー',
            text: `
<p>ソウル出身、韓国在住。チェソクミンとキムドンファンの2人からなるプロデューサーデュオ。</p>
<p>近年アメリカ進出も果たしグローバルな人気を見せるMonsta XのI.Mや、Block BのP.O、Block B BASTARZ等、韓国ビッグアーティストの楽曲を多数プロデュース。</p>
<p>多彩なセンスで人気の韓国トッププロデューサーデュオである。</p>`,
            content: `
<h5>プロデュース楽曲(作曲・アレンジ)</h5>
<p>
Monsta X (I.M) - SCENT<br/>
Monsta X (I.M) - Howlin'<br/>
Monsta X (I.M) - Burn<br/>
Monsta X (I.M) - Flower-ed<br/>
Block B BASTARZ - If not me who<br/>
Block B (P.O) - Comme des Garcons<br/>
MCND - Outro ; Kepler1649c<br/>
UP10TION - RESTROE<br/>
1TEAM - ICE IN THE CUP<br/>
Lin Fan - ALL RIGHT<br/>
Woo TaeWoon - Copy Ma Lyrics feat.Nafla<br/>
Crucial Star - I’m the one<br/>
Junoflo - Monday<br/>
Junoflo - Ausgang<br/>
Donutman - New life<br/>
Donutman - Hero
</p>

<h5>[LONG DRIVE ALBUM]</h5>
<p>
[EP] EP.01<br/>
[Single] bacon (Feat. X.Q, Jay Moon, LO VOLF)<br/>
[Single] wifey<br/>
[Single] no coment
</p>
`,
        }, {
            img: q.kondo,
            name: '近藤 章裕',
            pos: '顧問／プロデューサー／ボーカルトレーナー',
            text: `
<p>SHINee、INFINITE、Super Junior、EXO、NCT127他、数々のK-POPスターへのボーカルディレクションを韓国のスタジオで行い、コーラスやデモ制作など幅広く楽曲制作に携わる。</p>
<p>ボーカルコーチとして世界最先端の知識と技術でトップアーティストのボーカルコーチを担当、レコーディング時にボーカルディレクター・コーラスアレンジャーとして、オリコン上位作品を現在も多数担当。</p>`,
            url: '#',
            content: `
<h5>プロフィール</h5>
<p>
世界に通用するボーカル集団、ソウルマティックスのメンバー。
</p>
<p>
2015年アジアNo1規模の専門学校グループ滋慶学園グループスクールオブミュージック専門学校、2018年アメリカバークリー音楽学院提携校甲陽＆ダンス専門学校、全8校の教育顧問に就任。
</p>
<p>
ソウルマティックス主宰池末 信の培った多面的な分野と原則的な要素で培われたボーカルコーチメソッドを通して、国内のみならずグローバルに確かな実績を残している。
</p>
<p>
シンガー、プロデューサー、コーチとして、国内は勿論、ニューヨークやシカゴ、韓国や中国のツアー、プロジェクト等へ参加。
</p>
<p>
レコーディングのボーカルディレクター・コーラスアレンジャーとして、オリコン上位作品を多数担当。
</p>
<p>
<h5>コーラスレコーディング実績</h5>
<p>SHINee、INFINITE、Super Junior、EXO、長渕剛、鈴木雅之、Skoop On Somebody、水樹奈々、KinKi Kids、 湘南乃風、Dreams come true 他</p>

<h5>ボーカルディレクション実績</h5>
<p>SHINee、INFINITE、 F.CUZ、水樹奈々、高垣彩陽、CHiCO with HoneyWorks、DOBERMAN INFINITY(Vocal)、SALU、 DEEP SQUAD、ベッキー#、WARPs、他</p>

<h5>コーラスアレンジ実績</h5>
<p>湘南乃風、HAN-KUN(from湘南乃風)、GODIEGO、SALU、DEEP SQUAD、水樹奈々、 今市隆二(from 3代目 J soul brothers)、 SWAY(from DOBERMAN INFINITY)、高垣彩陽 他</p>

<h5>共演アーティスト</h5>
<p>平井堅、DOBERMAN INFINITY、KAZUKI(from DOBERMAN INFINITY)、イ・ホンギ(from FTISLAND)、David Bowie BAND、Tchami、Richard Hartley、 Donnie McClurkin、Dorinda Clark Cole 他</p>
`
        }, {
            img: q.soYonhun,
            name: 'ソ・ヨンフン',
            pos: 'プロデューサー/ボーカルディレクター/韓国語トレーナー',
            text: `
<p>ソウル出身。WINNERのMINO(ソンミンホ)、セブンティーンのプロデューサーであるケ・ボムジュ、ウ・テウンなどが出身であるROYAL CLASSというヒップホップクルーのメンバーを経験、韓国でSCOTCH VIP、CHANCEY THE GLOWという活動名でアーティストを活動を行う。</p>
<p>韓国の代表俳優チャン・ヒョクや俳優オ・ヨンソ、EXIDのヘリン、godのパク・ジュニョンなどが所属しているSidus HQで練習生を行いデビューの準備をする。</p>`,
            textMore: `
<p>世界を代表するK-POPフェスティバルであるM NET KCON JAPAN 2018にSEVENTEEN、WANNA ONE、TWICE、MONSTA Xなどと共に出演をする。</p>
<p>2019年には韓国で行われたBIGHIT Entertainment HIT IT 7 HIPHOP CompetitionでMCを行う。</p>
<p>プロデューサーとして、2020年に作曲したLin Fan - ALL RIGHTが中国のデイリーチャートで見事に1位を獲得。</p>
<p>来日しプロデューサー・アーティストとしてKELLY GREENとして活躍中。</p>`,
            url: '#',
            content: `
<h5>プロデュース楽曲(作曲・アレンジ)</h5>
<p>
Roy - FAKER<br/>
Lin Fan - ALL RIGHT（中国のデイリーチャート1位）<br/>
Lin Fan - 5:04<br/>
Lin Fan - NIGHTMARE<br/>
X TIME - Spotlight
</p>

<h5>ディレクター実績</h5>
<p>
Sidus HQ 練習生 Vocal Director<br/>
中国 [嘉行传媒] 練習生 Vocal Director<br/>
BIGHIT Entertainment HIT IT 7 HIPHOP Competition MC<br/>
S/S ACUOD by CHANU Music Director<br/>
Seoul Fashion Week Music Director
</p>

<h5>KELLY GREEN出演実績</h5>
<p>
REDDY & G2 Universe / G2's life Tour In Tokyo<br/>
Y-3 (Yojhi Yamamoto) SWAG<br/>
SIK-K Tokyo First Live<br/>
SIK-K Osaka First Live<br/>
DPR LIVE First Tokyo Live<br/>
2018 M.NET KCON JAPAN メインステージ<br/>
2018 AOMG GRAY Tokyo First Live<br/>
2018 KASS BLUE PLAYGROUND FESTIVAL<br/>
DEAN Tokyo Live<br/>
GROOVY ROOM Tokyo & Osaka Tour<br/>
ソウルホンデ GABBIA KELLY GREEN アルバムリリースパーティー<br/>
Jvcki Wai in Tokyo<br/>
Colde & APRO live in Tokyo<br/>
pH-1 & HAON live in Tokyo
<p/>

<h5>KELLY GREEN Reease実績</h5>
<p>
[Sinlge] SCOTCH VIP - Appear (2013)<br/>
[Single] SCOTCH VIP -  NONE.B (2013)<br/>
[Sinlge] SCOTCH VIP & ROYDO - DAY N’ NIGHT<br/>
[Single] CHANCEY THE GLOW- BOYEO<br/>
[Single] CHANCEY THE GLOW - Peter Pan<br/>
[EP] CHANCEY THE GLOW - Cynerge 0.5<br/>
[LP] CHANCEY THE GLOW - Good Afternoon<br/>
[LP] CHANCEY THE GLOW - Good Afternoon Instrumentals<br/>
[Sinlge] KELLY GREEN - Don’t Worry (2017)<br/>
[Single] KELLY GREEN - BE FRESH (2018)<br/>
[Single] KELLY GREEN - Black On Black (2018)<br/>
[EP] KELLY GREEN - NEW ASIAN 02-03 MIXTAPE (2019)<br/>
[Single] KELLY GREEN - Shinjuku Freestyle (2020)
</p>
`
        },  {
            img: q.kajitsu,
            name: 'KAJITSU',
            pos: 'ダンスディレクター',
        text: `
<p>2015年にCrayonpop Dance ContestやRc KoreaMusicDanceContestで優勝、EXILE、東方神起、D - LITE（BIGBANG）JEJUNG等のバックダンサー務め、2019年より1年間韓国の1MILLION DANCE STUDIOへダンス留学をする。</p>
<p>小さい頃からバレエ、ジャズを学びそれを生かしてガールズやjazzfankを得意とする。キッズの頃から、コンテストや、舞台に立ち経験を積む。　韓国留学ではパッションや表現力を吸収し帰国。帰国後はK - POPレッスン、アイドルのダンス指導や振付師などをメインに活動中。</p>`,
            content: `
<h5>経歴</h5>
<p>
2005〜2016年　anation act dancer<br/>
2005年　EXILE nagoya サポートダンサー<br />
2006年 TRF kidsツアーダンサー<br />
2008年　HEY!HEY!HEY! TRF kids dancer<br />
2010年　JUNSU/JEJUNG/YUCHUN THANKSGIVING LIVE IN DOME サポートダンサー<br />
2013年　DLITE D'scover TOUR ローカルダンサー<br />
2013年　東方神起SCREAM ダンスコンテスト優勝<br />
2013年　東方神起LIVETOUR TIME サーポトダンサー<br />
2015年　東方神起LIVETOUR WITH サポートダンサー<br />
2015年　crayonpop dance contest 優勝<br />
2016年　RC KOREA MUSIC DANCE CONTEST優勝<br />
2019年　東方神起LIVETOUR TOMORROW サポートダンサー <br />
2019年　乃木坂46 singout! MV出演
</p>
`,
        }, {
            img: q.mizuka,
            name: 'Mizuka',
            pos: 'ダンスディレクター',
            text: `
            7歳からチアダンスを始め、12歳からストリートダンスに転向しHipHopを中心にGirlsやLockin、R&Bなどを学ぶ。高校卒業後に韓国Seoulへ留学。現地のスタジオでダンスを修行し、韓国でK-POPの振付アシスタントやMV出演、またSeoulでトップクラスの大人気ダンススタジオの1Million Dance StudioのChoreography Videoに多数出演の実績を持つ。`,
            content: ``    
        }, {
            img: q.jondehyon,
            name: 'ジョン・デヒョン',
            pos: 'ディレクター/韓国語トレーナー',
            text: `
<p>兵庫県神戸市出身。</p>
<p>日韓ハーフでありながら3歳からインターナショナルスクールに通い、日米韓の3カ国語を習得。</p>
<p>多国籍文化を生かし、高校卒業後にはMajor LazerのメンバーであるDiplo やケヴィン•ハートの母校である米国テンプル大学へ進学。</p>
<p>韓国SEVENTEENの所属事務所であるPLEDIS Entertainment や韓国トップ俳優 アン•ジェヒョンの通訳を務めた事もある。</p>
<p>また、nodresscodeという活動名でヒップホップミュージックをベースとしたラッパー・シンガーソングライターとして活躍中。</p>`,
            content: false,
        }, {
            img: q.masuzima,
            name: '増島 朱音',
            pos: 'Chief Marketing Officer/韓国語トレーナー',
            text: `
<p>BIGBANGの日本デビューをきっかけにK-POP、韓国カルチャーに関心を寄せるようになる。</p>
<p>アパレルブランドでの勤務経験をしたことや自身が美容師免許を持ち、ヘアメイクやファッションに対する興味が多いことから、韓国音楽界の独特なファッションセンス、メイクアップ技術に感銘を受け、彼らのカルチャーを深く探求するようになり独学で韓国語を習得。</p>`,
            textMore: `
<p>その後少女時代、2NE1をメインとして日本、韓国はもちろん、北米各地やアジア各国でファンとしての活動を経験。K-POPファンダムの世界中での熱気を体感し、自身の経験からファン心理の解説を得意とする。</p>
<p>また、ヒップホップ、R＆BのDJとして渋谷を中心に都内クラブで活動。</p>
<p>海外都市でも多くのクラブカルチャーに触れ、特に韓国ソウルにおいてはDJやアーティストとの交流が深い。</p>
<p>ソウルのクラブパーティーで見る、欧米の最新チューンとミックスしても劣ることのないK-POP、K-HIPHOPの楽曲のクオリティーやオーディエンスの熱気を忘れられず、日本国内でもGRAY、DEAN、SIK-Kなど数多くの韓国アーティストの来日公演にてDJとして出演。</p>
<p>自身で開催するDJパーティーにおいても国や言語にとらわれないグローバルな選曲を見せている。</p>`,
            content: `
<h5>韓国アーティスト共演歴</h5>
<p>
SHOW ME THE MONEY＆UNPRETTY RAP STAR JAPAN SHOWCASE VOL.1<br/>
REDDY, G2 Universe x G2’s Life Tour In Tokyo<br />
Loopy, Nafla In Shibuya<br />
Sik-K First Live In Tokyo!!<br />
GRAY K-POWER FESTIVAL<br />
GroovyRoom K-P.O.W.E.R.<br />
REDDY Swag Hommes x K-P.O.W.E.R.<br />
Hash Swan Dayday K-P.O.W.E.R.
</p>
`,
        }
    ]
    const [isOpen, setIsOpen] = React.useState([])
    const refs = React.useRef([])
    const clickHandler = (e,i) => {
        let parrent = refs.current[i].current
        let accordion = parrent.querySelector('.accordion')
        let button = parrent.querySelector('.button') || parrent.querySelector('.button-open')
        let newIsOpen =  Array.from(isOpen)
        newIsOpen[i] = !isOpen[i]
        setIsOpen(newIsOpen)
        button.className = newIsOpen[i] ? "button-open" : "button"
    }

    const moreClick = (e,i) => {
        let parrent = refs.current[i].current
        let moreText = parrent.querySelector('.text-more')
        let moreButton = parrent.querySelector('.text-more-button')
        moreText.style.display = "block"
        moreButton.style.display = "none"
    }
    React.useEffect(()=>{
        console.log('render')
    })
    return(
        <section className={css.pIndexInstructors} id="instructors">
            <div className={css.pIndexInstructors__inner}>
                <h2 className={css.pIndexInstructors__title}>
                    <img src={q.title.publicURL} alt="講師紹介 - INSTRUCTORS -" />
                </h2>
                <h3 className={css.pIndexInstructors__subtitle}>
                    BLACKPINK、TWICE、SHINee、EXO、NCT127、Wanna One等のボーカルディレクション・楽曲提供、ダンサーを務める超一流の日韓講師陣
                </h3>
                <div className={css.pIndexInstructors__list}>
                    <ul className={css.pIndexInstructors__list__inner}>
                        { list.map( (val , i )=>{
                            const { img, name, pos, text, textMore, url, content } = val
                            const ref = React.createRef()
                            refs.current[i] = ref
                            return(
                                <li key={i} ref={ref}>
                                    <figure className="thumbnail">
                                        <Image data={img.childImageSharp}/>
                                    </figure>
                                    <div className="detail">
                                        <p className="name">{name}</p>
                                        <p className="pos">{pos}</p>
                                        <div className="text" dangerouslySetInnerHTML={{__html: text}}/>
                                        { textMore && <div className="text-more-button" onClick={ (e) => moreClick(e,i) }>...もっと読む</div>}
                                        { textMore && <div className="text-more" style={{display: 'none'}} dangerouslySetInnerHTML={{__html: textMore}}></div>}
                                        <div className="footer">
                                            {/* <Link className="button" to={url} blank>
                                                実績・事例を見る<span className="icon"></span>
                                            </Link> */}
                                            { content &&
                                                <div className="button" onClick={(e) => clickHandler(e,i)}>
                                                    実績・事例を見る<span className="icon"></span>
                                                </div>
                                            }
                                        </div>
                                        <div className="accordion" style={{display: isOpen[i] ? 'block' : 'none'}} dangerouslySetInnerHTML={{__html: content}}>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <p className={css.pIndexInstructors__textSmall}>
                    その他に、エンターテインメント各界からの著名人をお招きした特別なワークショップの開催予定しています。※別途チケット制
                </p>
            </div>
        </section>
    )
}
