import * as React from "react"
import { graphql} from "gatsby"
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import SEO from "@components/atoms/Seo"
import Hero from "@components/projects/IndexHero"
import Special from "@components/projects/IndexSpecial"
import About from "@components/projects/IndexAbout"
import Instructors from "@components/projects/IndexInstructors"
import Lessons from "@components/projects/IndexLessons"
import Moves from "@components/projects/IndexMoves"
import Interviews from "@components/projects/IndexInterviews"
import Audition from "@components/projects/IndexAudition"
import Message from "@components/projects/IndexMessage"
import Access from "@components/projects/IndexAccess"
// import CommingSoon from "@components/projects/CommingSoon"
import Topicks from "@components/projects/IndexTopicks"
import TopMovie from "@components/projects/IndexTopMovie"
import Workshop from "@components/projects/IndexWorkshop"

// import { Section, SectionHeader } from "@atoms/Section"


function IndexPage()
{
    return (
        <>
            <SEO/>
            {/* <CommingSoon/> */}
            <Hero/>
            <TopMovie/>
            <Special/>
            <About/>
            <Topicks/>
            <Instructors/>
            <Workshop/>
            <Lessons/>
            <Moves/>
            <Interviews/>
            <Audition/>
            <Message/>
            <Access/>
        </>
    )
}

export const pagerQuery = graphql`
    fragment Thumbnail on ImageSharp {
        gatsbyImageData(
            width: 1280
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 80
        )
    }
    fragment InterviewIcon on ImageSharp {
        gatsbyImageData(
            width: 240
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 80
        )
    }
    fragment SnsIcon on ImageSharp {
        gatsbyImageData(
            width: 88
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
`
export default IndexPage
