import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@atoms/Link"
import { Section, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-audition.module.styl"

export default function IndexAudition()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexAudition__lbl__title.svg" }) {
                    publicURL
            }
            bg : file(relativePath: { eq: "IndexAudition__img__bg.jpg" }) { 
                publicURL
            }
        }
    `)
    return(
        <section id="audition" className={css.pIndexAudition} style={{ backgroundImage: `url(${q.bg.publicURL})` }}>
            <div className={css.pIndexAudition__inner}>
                <h2 className={css.pIndexAudition__title}><img src={q.title.publicURL} alt="入学オーディション - AUDITION -" /></h2>
                <p className={css.pIndexAudition__text}>K-POPが好き、他のスクールじゃ物足りない、韓国デビューを目指したい…。本気でアーティストデビューを目指すアカデミー生を募集します。レッスンクオリティを保証するため、40名限定募集です。</p>
                <div className={css.pIndexAudition__pdf}>
                    <a className="button" href="/pdf/special1_auditioninfo.pdf" target="_blank">
                        入学オーディション案内 (PDF)
                    </a>
                        <p>※お申し込みの前に必ずお読みください。</p>
                </div>
                <div className={css.pIndexAudition__footer}>
                    <div className={css.pIndexAudition__footer__ballonWrapper}>
                        <p className={css.pIndexAudition__footer__ballon}>ただいま練習生 募集中！</p>
                    </div>
                    <Link className={css.pIndexAudition__footer__button} to="https://docs.google.com/forms/d/e/1FAIpQLSfZk2Yhb9H2b8kdo_HF-kXtJK1w8cGgPMZl2FF3HezhXvJAtA/viewform">
                        次回オーディション情報をみる
                    </Link>
                    <ul>
                        <li>
                            アカデミーレッスン費<br/>
                            <span>¥50,000 (税別)</span> ⇒ <b>開校特別割引 ¥30,000(税別)</b>
                        </li>
                        <li>入会金／事務手数料 ¥30,000（税別）</li>
                        <li>入学オーディションの結果で特待生制度あり</li>
                        <li>特別ワークショップは別途チケット制</li>
                        <li>入学オーディションは無料です</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
