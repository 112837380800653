import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"

import * as css from "@css/projects/p-index-message.module.styl"

export default function IndexMessage()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexMessage__lbl__title.svg" }) {
                    publicURL
            }
            ceo : file(relativePath: { eq: "IndexMessage__img__ceo.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)

    const moreRef = React.useRef()
    const moreButtonRef = React.useRef()
    const clickMore = () => {
        moreRef.current.style.display = 'block'
        moreButtonRef.current.style.display = 'none'
    }
    return(
        <div id="message" className={css.pIndexMessage}>
            <div className={css.pIndexMessage__inner}>
                <h2 className={css.pIndexMessage__title}>
                    <img src={q.title.publicURL} alt="代表からのメッセージ - MESSAGE FROM CEO -"/>
                </h2>
                <div className={css.pIndexMessage__text}>
                    <p>
                    「世界・アジアは次なるSPECIAL1を待っています。」
                    </p>
                    <p>
                        私は22年間、東京で音楽とエンターテイメントの世界で仕事をしております。<br/>
                        LA、NY、PARIS、LONDON、SEOUL他、招聘した海外アーティストはグラミー賞での受賞やビルボードチャート1位を獲得したことのあるアーティストを含め300組以上、そして国内のトップアーティストでは1500回以上の公演を企画・プロデュースを行ってまいりました。
                    </p>
                    <p>
                        又、自社の所属アーティストをTOY'S FACTORYさんやUniversal Music Japanさんのような大手のレコード会社からメジャーデビューをさせました。
                    </p>
                    <p>
                        しかし、近年エンタメの世界でも、誰もが経験したことのないウイルスによって、業界全体の状況は一変し、ライブやイベントもキャンセル、自粛そして楽曲のリリースも相次ぎ延期となりました。
                    </p>
                    <p>
                        私は今後のエンタメ業界について考え続ける中で、今こそ、これまでの経験や人脈を活かして、新しく面白いそしてクリエイティブなことをするべきだという結論に至りました。
                    </p>
                    <p>
                        それは以前より進めていた「日本とアジアのアーティストやクリエイターを結びたい、世界で活躍できるコンテンツと土壌をつくり上げたい」という目標を叶えることです。その為に、これまで一緒に仕事をさせてもらったアーティストや関係者の皆さんに連絡し提案をしました。
                    </p>
                    <p>
                        縁もあり5年程前から、韓国のDEANをはじめとするGRAY（AOMG）DPRLIVEなど韓国のトップアーティストを日本に招聘しツアーを主催し彼らと仕事をすることで日本とはまた異なるプロフェッショナルさと、応援する日本のファンの方々の熱量をSNSや実際のライブイベントなどからダイレクトに感じていました。
                    </p>
                    <p>
                        近年世界で活躍が目覚ましい彼らと、次なるSPECIAL1（特別な人）を育てたい。<br/>
                        その想いを伝え続けて半年がたったとき。<br />
                        このアカデミーの特長である一流の日韓講師陣が集まりました。
                    </p>
                    <p>
                        弊社ならではのキャリア、強みを活かし最高のスタッフィングと、音楽・ダンスの聖地中目黒に駅徒歩90秒の好立地にて100平米の大きなスタジオを作りました。
                    </p>
                    <p>
                        BLACKPINK 、TWICE、SHINee、EXO、<br/>
                        NCT127、Wanna One、、、<br/>
                        K-POPスターを支えている講師陣が、あなたをSPECIAL1に育てます。
                    </p>
                    <p>
                        私自身が高校卒業後に10代の頃、音楽の専門学校に通い感じた事、良かったこと、満足出来なかった事、将来に対してもったビジョンを思い出し
                        TEAM SPECIAL1としては結果にこだわりたく、厳しく、細かく、情熱を持って伝えていければと思います。
                    </p>
                    <p>
                        「SPECIAL1は決して才能が突出している方のみを探しているわけではありません<br/>
                        皆さんそれぞれの個性、人生＝SPECIAL1だと思っています」
                    </p>
                    <p>
                        あきらめない気持ち、実現できると自分を信じる勇気が一番重要です。それさえあれば道、チャンスは必ず巡って来ます。
                    </p>
                    <p>
                        さぁ、1年後、私たちと一緒に<br/>
                        アジア・世界へと勝負していきましょう。
                    </p>
                </div>
                <div className={css.pIndexMessage__card}>
                    <figure className="img">
                        <Image data={q.ceo.childImageSharp}/>
                    </figure>
                    <div className="detail">
                        <div className="header">
                            <p className="name">西村洋一</p>
                            <p className="pos">
                                <span>株式会社WONDER&CLOCKS 代表取締役</span>
                                <span>SPECIAL1 EXECUTIVE PRODUCER</span>
                            </p>
                        </div>
                        <div className="text">
                            1976年生まれ 兵庫県神戸市出身<br/>
                            1999年ロンドンに留学。<br />
                            帰国後上京、「株式会社ミュージックインターゲート」に入社。後に同社が改名したイベントや空間プロデュースカンパニーの「株式会社グローバルハーツ」の立ち上げよりディレクターとして9年間勤める。<br />
                            <div ref={moreButtonRef} onClick={clickMore} className="text-more-button">...もっと読む</div>
                            <div ref={moreRef} className="text-more" style={{display: 'none'}}>
                                「代官山AIR」のブランディング、世界 国内のトップアーティストのイベントを東京、全国で企画、プロデュースする。<br />
                                2009年4月に「株式会社WONDER&CLOCKS」を設立、ライブエンターテイメント・コンテンツプロデュースに特化した事業を国内外で展開。今年で13期目を迎えた。2019年より業務委託にて「ALPHABOAT合同会社」（SCデジタルメディア/住友商事）のエンターテイメントユニットのプロデューサーを務め、YouTubeをはじめとしたデジタルエンターテイメントに注力する。<br />
                                2021年5月、中目黒駅前に100平米のエンターテイメント・スタジオをオープンさせる。<br />
                                そして、アフターコロナを見据え、アジア・世界を熱く、笑顔にさせる事をミッションに、6月よりクリエイティブ・ファーストを掲げ、アーティスト・クリエイターの人材の発掘、育成をテーマに日韓のスペシャリストと共に「SPECIAL1プロジェクト」をスタートさせる。<br />
                                <br />
                                WONDER&CLOCKS<br />
                                <Link to="https://www.wonder-clocks.com" blank>https://www.wonder-clocks.com</Link><br />
                                ALPHABOAT<br />
                                <Link to="https://www.wonder-clocks.com" blank>https://www.alpha-boat.com/brands</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
