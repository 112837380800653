import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import News from "@projects/IndexHeroNews"
import { Link } from "@atoms/Link"
import * as css from "@css/projects/p-index-hero.module.styl"
import { BgImg } from "@atoms/Image"
import { useEventListener } from "@hooks/useEventListener"
// import { Placeholder } from "gatsby-plugin-image"


export default function IndexHero()
{
    const q = useStaticQuery(graphql`
        query {
            kv : file(relativePath: { eq: "indexHero__img__kv.jpg" }) {
                publicURL
                childImageSharp {
                    gatsbyImageData(
                        width: 960
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                        quality: 100
                    )
                }
            }
            bg : file(relativePath: { eq: "IndexAudition__img__bg.jpg" }) {
                publicURL
            }
        }
    `)
    const timer = useRef()
    const [isResize,setIsResize] = useState(1)

    useEffect(()=>{
        if( window === 'undefined' ) return
        setIsResize(window.innerWidth)
    })
    
    useEventListener('resize',()=>{
        if (timer.current > 0) {
            clearTimeout(timer.current);
        }
         
        timer.current = setTimeout(()=> {
            const w = window.innerWidth
            console.log(w)
            setIsResize(w)
        }, 200)
    })

    const Content = () => {
        return(
            <div className={css.pIndexHero__inner}>
                {/* <div className={css.pIndexHero__header}>
                    <News />
                </div> */}
                <div className={css.pIndexHero__center}>
                    <h1>
                        <span>ホンモノのK-POPから学ぶ。</span><br/>
                        <span>世界を目指すキミのための</span>
                        <span>アーティストアカデミー。</span>
                    </h1>
                    {/* <h2>2021年6月、中目黒に開校！</h2> */}
                </div>
                <div className={css.pIndexHero__footer}>
                    <div className={css.pIndexHero__footer__ballonWrapper}>
                        <p className={css.pIndexHero__footer__ballon}>
                            練習生募集！特待生制度あり！
                        </p>
                    </div>
                    <Link className={css.pIndexHero__footer__button} to="/" scroll="#audition">
                        次回オーディション情報を見る
                    </Link>
                </div>
            </div>
        )
    }
    return(
        <section id="top" className={css.pIndexHero}>
            {/* <div className={css.pIndexHero__bg} style={{ backgroundImage: `url(${q.kv.publicURL})` }}/> */}
                <BgImg className={css.pIndexHero__bgSp} data={q.kv.childImageSharp} style={{backgroundSize: isResize < 768 ? 'cover' : 'contain'}} >
                    <Content/>
                </BgImg>
        </section>
    )
}
