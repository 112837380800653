// extracted by mini-css-extract-plugin
export var pIndexAbout = "sp1m";
export var pIndexAbout__inner = "sp1n";
export var pIndexAbout__inner2 = "sp1o";
export var pIndexAbout__inner2__inner = "sp1p";
export var pIndexAbout__title = "sp1q";
export var pIndexAbout__subtitle = "sp1r";
export var pIndexAbout__imgMax = "sp1s";
export var pIndexAbout__paragraph = "sp1t";
export var pIndexAbout__list = "sp1u";
export var animationMarquee = "sp1v";