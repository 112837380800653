import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
// import { Section2, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-topicks.module.styl"

export default function IndexTopicks()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexTopicks__lbl__title.svg" }) {
                    publicURL
            }
            img01 : file(relativePath: { eq: "IndexTopicks__img__01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img02 : file(relativePath: { eq: "IndexTopicks__img__02.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img03 : file(relativePath: { eq: "IndexTopicks__img__03.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img04 : file(relativePath: { eq: "IndexTopicks__img__04.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img05 : file(relativePath: { eq: "IndexTopicks__img__05.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img06 : file(relativePath: { eq: "IndexTopicks__img__06.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    let list = [
        [q.img01,
            'S1練習生が少女時代、NCT、などK-POPスターが多く所属する韓国大手事務所のSMエンターテインメントに練習生として合格!!',
            null
        ],
        [q.img02,
            'ITZYの公式TikTokアカウントでS1練習生のダンス動画が取り上げられる!!', 
            'itzyofficial_jpのTikTokアカウントによるVoltageChallengeでITZYメンバーとデュエット!!コラボ動画は200,000イイネを獲得!!'
        ],
        [q.img03,
            'eill「ただのギャル」Choreography Videoにダンサーとして起用され、出演!!',
            'BTS「Butter」の振り付けをしたダンスユニットGANMIとともにS1練習生がバックダンサーとしてMV出演!!<br> ▼Choreograhy Video <a href="https://youtu.be/6eXhb0yFRNk" target="_blank">https://youtu.be/6eXhb0yFRNk</a>'
        ],
        [q.img04,
            '不定期でS1オーディションを開催中!!成績優秀者は韓国の事務所への紹介、またはSPECIAL1 ENTERTAINMENT ACADEMYからデビュー!!',
             null
        ],
        [q.img05,
            '年に1.5回 アカデミー主催のショーケースを都内の会場にて開催!!',
            null
        ],
        [q.img06,
            'S1練習生によるショーケースを開催!!',
            'アリアナ・グランデ等の世界的スターもステージに立った渋谷のSOUND MUSEUM VISIONにて発表会を開催。課題曲にはK-POPを中心に、BLACKPINKが練習生時代に歌っていた洋楽も使用され、ダンスだけではなくボーカルパフォーマンスや韓国語を用いたパフォーマンスも入り、他のアカデミーではみられないグローバルスタンダードを推奨するS1の特色が色濃く出たステージになった。'
        ],

    ]
    return(
        <div id="topicks" className={css.pIndexAbout}>
            <div className={css.pIndexAbout__inner}>
                <h2 className={css.pIndexAbout__title}><img src={q.title.publicURL} alt="アカデミーTOPICKS" /></h2>
            </div>
            <div className={css.pIndexAbout__inner2}>
                <div className={css.pIndexAbout__inner2__inner}>
                    <ul className={css.pIndexAbout__list}>
                    { list.map((val,i)=>{
                        console.log(val)
                        return(
                            <li key={i}>
                                <figure className={css.aImg}>
                                    <Image data={val[0].childImageSharp} />
                                </figure>
                                <div className={css.aDesc}>
                                    <h3 className={css.aTitle}>{val[1]}</h3>
                                    <p className={css.aText} dangerouslySetInnerHTML={{__html: val[2]}}></p>
                                </div>
                            </li>
                        )
                    })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
