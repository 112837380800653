import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import { Link } from "@atoms/Link"
// import { Section2, SectionHeader } from "@atoms/Section"
// import YouTube from 'react-youtube'
import Iframe from 'react-iframe'
import * as css from "@css/projects/p-index-topmovie.module.styl"

export default function IndexTopMovie()
{
    const q = useStaticQuery(graphql`
        query {
            commingsoon : file(relativePath: { eq: "IndexTopMovie__img__commingsoon.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    return(
        <div id="top-movie" className={css.pIndexTopmovie}>
            <div className={css.pIndexTopmovie__inner}>
                {/* <figure className={css.aCs}>
                    <Image data={q.commingsoon.childImageSharp}/>
                </figure> */}
                <div className={css.aYoutube}>
                    <Iframe id="myId" width="560" height="315" src="https://www.youtube.com/embed/6jn3iP7hUqE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Iframe>
                </div>
            </div>
        </div>
    )
}
