import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
import { Section, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-interviews.module.styl"

export default function IndexInterviews()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexInterviews__lbl__title.svg" }) {
                    publicURL
            }
            chara01 : file(relativePath: { eq: "IndexInterviews__img__chara01.png" }) { childImageSharp {
                    ...InterviewIcon
            } }
            chara02 : file(relativePath: { eq: "IndexInterviews__img__chara02.png" }) { childImageSharp {
                    ...InterviewIcon
            } }
            instagram : file(relativePath: { eq: "IndexInterviews__icn__instagram.png" }) { childImageSharp {
                    ...SnsIcon
            } }
        }
    `)
    let list = [
        {
            title: '日韓でのデビューに向けて頑張っています!!',
            name: 'MANAMIさん',
            img: q.chara01,
            text: '福岡でダンススクールに通っていて環境を変えたいと思っていた時にS1のオーディションを見つけました。自分の可能性を確かめたいと思い上京してきました。S1ではメンバーのみんなと意識を高め合い、他のアカデミーでは経験出来ないような事を通して1年間で成長してきました。私自身のクリエイティブな部分もパフォーマンスの中で出せるようになってきて、今は個性を活かしたパフォーマンスを磨いて日韓でのデビューに向けて頑張っています!!',
            url: '#',
            acount: 'https://www.instagram.com/manameexx/',
        }, {
            title: 'これまで教わったことがない指導が受けられる！',
            name: 'KOSUKEさん',
            img: q.chara02,
            text: '今までたくさんヴォーカルレッスンを受けてきましたが、近藤先生は今まで教わったことがなかった角度から説明をしてくださり、歌うことについての考え方が変わりました。ヨンフン先生は先生！というよりお兄ちゃんの様な感じで、アットホームなレッスンで楽しいのはもちろんなのですが、技術面でもオシャレに聞こえる歌い方を教えてもらってタメになりました！',
            url: '#',
            acount: 'https://www.instagram.com/kosskeetit/?igshid=YzAyZWRlMzg',
        },
    ]
    return(
        <section className={css.pIndexInterviews} id="interviews">
            <div className={css.pIndexInterviews__inner}>
                <h2 className={css.pIndexInterviews__title}><img src={q.title.publicURL} alt="練習生インタビュー - INTERVIEWS -" /></h2>
                <div className={css.pIndexInterviews__list}>
                    <ul>
                        { list.map( (val , i )=>{
                            const { title, name, img, text, url, acount} = val
                            return(
                                <li key={i}>
                                    <div className="header">
                                        <figure className="img">
                                            <Image data={img.childImageSharp} alt=""/>
                                        </figure>
                                        <div className="detail">
                                            <p className="title">{title}</p>
                                            <div className="instagram">
                                                <Link to={acount} blank>
                                                    <span className="name">{name}</span><span className="icon"><Image data={q.instagram.childImageSharp}/></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text" dangerouslySetInnerHTML={{__html: text}}/>
                                    {/* <div className="footer">
                                        <Link className="button" to={url}>
                                            続きをnoteで読む
                                            <span className="icon"></span>
                                        </Link>
                                    </div> */}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </section>
    )
}
