import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
// import { Section2, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-about.module.styl"

export default function IndexAbout()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexAbout__lbl__title.svg" }) {
                    publicURL
            }
            catch : file(relativePath: { eq: "IndexAbout__lbl__catch1.svg" }) {
                publicURL
            }
            num1 : file(relativePath: { eq: "common__icn__num1.svg" }) {
                    publicURL
            }
            num2 : file(relativePath: { eq: "common__icn__num2.svg" }) {
                    publicURL
            }
            num3 : file(relativePath: { eq: "common__icn__num3.svg" }) {
                    publicURL
            }
            num4 : file(relativePath: { eq: "common__icn__num4.svg" }) {
                    publicURL
            }
            num5 : file(relativePath: { eq: "common__icn__num5.svg" }) {
                    publicURL
            }
            num6 : file(relativePath: { eq: "common__icn__num6.svg" }) {
                publicURL
            }
            img01 : file(relativePath: { eq: "IndexAbout__img__01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    let list = [
        [q.num1,'韓国芸能事務所の育成メソッドを取り入れた独自カリキュラム'],
        [q.num2,'ソウル、東京の最前線で活躍する講師、プロデューサー陣が直接指導'],
        [q.num3,'1ヶ月4回のレッスンで合計約840分の韓国式レッスン（VOCAL+DANCE+韓国語ラップ/韓国語+月末評価テスト）'],
        [q.num4,'ネイティブによる韓国語のレッスンで、日常会話から韓国オーディションにも対応可能'],
        [q.num5,'東京・中目黒駅前、徒歩90秒。トップアーティストも利用する自社スタジオでのレッスン。'],
        [q.num6,'韓国の事務所またはS1アカデミーよりデビュー'],

    ]
    return(
        <div id="about" className={css.pIndexAbout}>
            <div className={css.pIndexAbout__inner}>
                <h2 className={css.pIndexAbout__title}><img src={q.title.publicURL} alt="アカデミーの特徴 - About -" /></h2>
                <h3 className={css.pIndexAbout__subtitle}>
                    <img src={q.catch.publicURL} alt="DANCE x VOCAL x RAP (韓国語) 世界を目指せるカリキュラムがここにある。" />
                </h3>
            </div>
            <div className={css.pIndexAbout__inner2}>
                <div className={css.pIndexAbout__inner2__inner}>
                    <div className={css.pIndexAbout__paragraph}>
                        <p>
                            現在、様々なガールズグループが活動する世界において、K-POPスターのような一流のアイドルになるためにはダンスも重要ですが、歌唱力、ラップスキル、そして語学力、人間力と、総合的なアーティストパフォーマンスがグローバルスタンダードになっている中で、本アカデミーでは特にボーカル・韓国語のラップレッスンに力を入れて、現在も一流のK-POPスターと実際に仕事をしている講師陣によるカリキュラムを構築しています。
                        </p>
                    </div>
                    <figure className={css.pIndexAbout__imgMax}>
                        <Image data={q.img01.childImageSharp} alt=""/>
                    </figure>
                    <ul className={css.pIndexAbout__list}>
                    { list.map((val,i)=>{
                        console.log(val)
                        return(
                            <li key={i}>
                                <span className="icon">
                                    <img src={val[0].publicURL} alt={i + 1} />
                                </span>{val[1]}
                            </li>
                        )
                    })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
