import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
import { Section, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-movies.module.styl"

export default function IndexMovies()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexMovies__lbl__title.svg" }) {
                    publicURL
            }
            comingsoon : file(relativePath: { eq: "IndexMovies__img__comingsoon.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            comingsoon01 : file(relativePath: { eq: "IndexMovies__img__comingsoon-01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            comingsoon02 : file(relativePath: { eq: "IndexMovies__img__comingsoon-02.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            comingsoon03 : file(relativePath: { eq: "IndexMovies__img__comingsoon-03.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    let list = [
        {
            title: '講師陣からのメッセージ',
            movie: false,
        }, {
            title: 'レッスン風景',
            movie: false,
        }, {
            title: '練習生インタビュー',
            movie: false,
        }
    ]
    return(
        <section className={css.pIndexMovies} id="movies">
            <div className={css.pIndexMovies__inner}>
                <h2 className={css.pIndexMovies__title}>
                    <img src={q.title.publicURL} alt="ムービー - MOVIES -" />
                </h2>
                {/* <h3 className={css.pIndexMovies__subtitle}>韓国トップアーティストへの指導経験を持つ一流の講師が集結。</h3> */}
                <div className={css.pIndexMovies__list}>
                    <ul>
                        { list.map( (val , i )=>{
                            const { title, movie } = val
                            return(
                                <li key={i}>
                                    <h4>{title}</h4>
                                    {
                                        movie ?
                                            <div>iframe</div>
                                            :
                                            <figure>
                                                <Image data={q[`comingsoon0${i+1}`].childImageSharp} alt="coming soon"/>
                                            </figure>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </section>
    )
}
