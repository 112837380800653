// extracted by mini-css-extract-plugin
export var pIndexHero = "sp1w";
export var pIndexHero__bg = "sp1x";
export var pIndexHero__bgSp = "sp1y";
export var pIndexHero__inner = "sp1z";
export var pIndexHero__header = "sp1A";
export var pIndexHero__center = "sp1B";
export var pIndexHero__footer = "sp1C";
export var pIndexHero__footer__ballonWrapper = "sp1D";
export var pIndexHero__footer__ballon = "sp1E";
export var pIndexHero__footer__button = "sp1F";
export var animationMarquee = "sp1G";