// extracted by mini-css-extract-plugin
export var pIndexAudition = "sp1X";
export var pIndexAudition__inner = "sp1Y";
export var pIndexAudition__title = "sp1Z";
export var pIndexAudition__text = "sp1_";
export var pIndexAudition__pdf = "sp1-";
export var pIndexAudition__footer = "sp10";
export var pIndexAudition__footer__ballonWrapper = "sp11";
export var pIndexAudition__footer__ballon = "sp12";
export var pIndexAudition__footer__button = "sp13";
export var animationMarquee = "sp14";