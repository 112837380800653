import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
import { Section, SectionHeader } from "@atoms/Section"

import * as css from "@css/projects/p-index-lessons.module.styl"

export default function IndexLessons()
{
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "IndexLessons__lbl__title.svg" }) {
                    publicURL
            }
            catch1 : file(relativePath: { eq: "IndexLessons__lbl__catch1.svg" }) {
                publicURL
            }
            main : file(relativePath: { eq: "IndexLessons__img__main.svg" }) {
                    publicURL
            }
            system : file(relativePath: { eq: "IndexLessons__img__system.svg" }) {
                publicURL
            }
            img01_01 : file(relativePath: { eq: "IndexLessons__img01_01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img01_02 : file(relativePath: { eq: "IndexLessons__img01_02.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            img02_01 : file(relativePath: { eq: "IndexLessons__img02_01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
            img02_02 : file(relativePath: { eq: "IndexLessons__img02_02.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            img03_01 : file(relativePath: { eq: "IndexLessons__img03_01.jpg" }) { childImageSharp {
                    ...Thumbnail
            } }
        }
    `)
    let list = [
        {
            t1: 'Vocal Lesson',
            t2: 'ボーカルレッスン',
            img:  q.img01_01,
            text: '喉頭周りの筋力トレーニングや筋肉の使い方の様々なエクササイズ、声の共鳴、姿勢や呼吸が及ぼす神経伝達への影響など、様々なメソッドの観点を基に、国内外のクリエイティブ第一線でボーカルディレクターとして長年、そして今も培い続けているノウハウと実績を通して、”世界に通用するシンガー”への確実な成長を促します。',
            feats: [
                '国内外アーティストへのプロデュースも担当する講師によるハイレベルなレッスン',
                '韓国芸能事務所を経験した講師による韓国スタイルの練習カリキュラム',
                '韓国在住のプロ講師陣からの特別オンラインレッスンも実施',
                '実力や課題が視覚的にわかる月末評価で、具体的かつ献身的なアドバイス',
            ]
        }, {
            t1: 'Dance Lesson',
            t2: 'ダンスレッスン',
            img: q.img02_01,
            text: 'K-POPアーティストの振付やサポートを担当した指導陣による本格レッスン。ダンスのキレ、表現力などを磨きます。',
            feats: [
                '経歴が物語る実力派講師陣による本格レッスン',
                '有名ダンサーによるワークショップの開催（別途チケット制となります）',
                '韓国在住のプロ講師による特別オンラインレッスンも実施',
                '実力や課題が視覚的にわかる月末評価で、具体的かつ献身的なアドバイス',
            ]
        }, {
            t1: 'Korean Lesson',
            t2: '韓国語レッスン',
            img: q.img03_01,
            text: '音楽番組、K-POP最新ニュースなどを題材とした韓国語学習で、韓国でのオーディション対策や現場で使える専門的な韓国語を学べます。',
            feats: [
                '基礎からの学習で初心者でも日常会話の習得が可能',
                '韓国ネイティブスピーカーによる発音指導',
                '韓国語でのボーカル、ダンスレッスンで韓国デビューの対策も'
            ]
        }
    ]
    return(
        <section className={css.pIndexLessons} id="lessons">
            <div className={css.pIndexLessons__inner}>
                <h2 className={css.pIndexLessons__title}>
                    <img src={q.title.publicURL} alt="レッスン内容 - LESSONS -" />
                </h2>
                {/* <h3 className={css.pIndexLessons__subtitle}
                    >韓国トップアーティストへの指導経験を持つ一流の講師が集結。
                </h3> */}
                <figure className={css.pIndexLessons__img}>
                    <img src={q.main.publicURL} alt="ボーカルレッスン75分+ダンスレッスン75分+韓国語ラップ/韓国語レッスン+45分 x 4回/月 + レベルチェック（毎月1回・最終週）月末評価（3ヶ月に1回・最終週）"/>
                </figure>
                <p className={css.pIndexLessons__textSmall}>
                    ※オンライン型のアカデミーも開校予定です
                </p>
                <figure className={css.pIndexLessons__catch1}>
                    <img src={q.catch1.publicURL}/>
                </figure>
                <figure className={css.pIndexLessons__system}>
                    <img src={q.system.publicURL}/>
                </figure>
                <p className={css.pIndexLessons__systemText}>
                レベルチェック、月末評価にて<b>スター性/テクニック/メンタリティー/人間性</b>を総合的に考慮し、審査、クラスの振り分けが行われます。
                </p>
            </div>
            <div className={css.pIndexLessons__lessons}>
                <section>
                    <div className="header">
                        <div className="inner">
                            <h4>Vocal Lesson</h4>
                            <h5>ボーカルレッスン</h5>
                        </div>
                    </div>
                    <div className="detail">
                        <figure className="img">
                            <Image data={q.img01_01.childImageSharp}/>
                        </figure>
                        <figure className="img">
                            <Image data={q.img01_02.childImageSharp}/>
                        </figure>
                        <div>
                            喉頭周りの筋力トレーニングや筋肉の使い方の様々なエクササイズ、声の共鳴、姿勢や呼吸が及ぼす神経伝達への影響など、様々なメソッドの観点を基に、国内外のクリエイティブ第一線でボーカルディレクターとして長年、そして今も培い続けているノウハウと実績を通して、”世界に通用するシンガー”への確実な成長を促します。
                        </div>
                        <div className="features">
                            <p>レッスンの特長</p>
                            <ul>
                                <li>国内外アーティストへのプロデュースも担当する講師によるハイレベルなレッスン</li>
                                <li>韓国芸能事務所を経験した講師による韓国スタイルの練習カリキュラム</li>
                                <li>韓国在住のプロ講師陣からの特別オンラインレッスンも実施</li>
                                <li>実力や課題が視覚的にわかる月末評価で、具体的かつ献身的なアドバイス</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="header">
                        <div className="inner">
                            <h4>Dance Lesson</h4>
                            <h5>ダンスレッスン</h5>
                        </div>
                    </div>
                    <div className="detail">
                        <figure className="img">
                            <Image data={q.img02_01.childImageSharp}/>
                        </figure>
                        <div>
                            K-POPアーティストの振付やサポートを担当した指導陣による本格レッスン。ダンスのキレ、表現力などを磨きます。
                        </div>
                        <figure className="img">
                            <Image data={q.img02_02.childImageSharp}/>
                        </figure>
                        
                        <div className="features">
                            <p>レッスンの特長</p>
                            <ul>
                                <li>経歴が物語る実力派講師陣による本格レッスン</li>
                                <li>有名ダンサーによるワークショップの開催（別途チケット制となります）</li>
                                <li>韓国在住のプロ講師による特別オンラインレッスンも実施</li>
                                <li>実力や課題が視覚的にわかる月末評価で、具体的かつ献身的なアドバイス</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="header">
                        <div className="inner">
                            <h4>Korean Lesson</h4>
                            <h5>韓国語ラップ/韓国語レッスン</h5>
                        </div>
                    </div>
                    <div className="detail">
                        <figure className="img">
                            <Image data={q.img03_01.childImageSharp}/>
                        </figure>
                        <div>
                        K-POPヒット曲の中から韓国語ラップの部分を使用して韓国語ラップレッスンを実施。また、音楽番組、K-POP最新ニュースなどを題材とした韓国語学習で、韓国でのオーディション対策や現場で使える専門的な韓国語を学べます。
                        </div>
                        <div className="features">
                            <p>レッスンの特長</p>
                            <ul>
                                <li>基礎からの学習で初心者でも日常会話の習得が可能</li>
                                <li>韓国ネイティブスピーカーによる発音指導</li>
                                <li>韓国語でのボーカル、ダンスレッスンで韓国デビューの対策も</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}
