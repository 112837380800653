// extracted by mini-css-extract-plugin
export var pIndexAbout = "sp1La";
export var pIndexAbout__inner = "sp1Ma";
export var pIndexAbout__inner2__inner = "sp1Na";
export var pIndexAbout__title = "sp1Oa";
export var pIndexAbout__subtitle = "sp1Pa";
export var pIndexAbout__imgMax = "sp1Qa";
export var pIndexAbout__paragraph = "sp1Ra";
export var pIndexAbout__list = "sp1Sa";
export var aDesc = "sp1Ta";
export var aTitle = "sp1Ua";
export var aText = "sp1Va";
export var animationMarquee = "sp1Wa";