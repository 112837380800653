// extracted by mini-css-extract-plugin
export var pIndexLessons = "sp1Xa";
export var pIndexLessons__inner = "sp1Ya";
export var pIndexLessons__inner2 = "sp1Za";
export var pIndexLessons__title = "sp1_a";
export var pIndexLessons__subtitle = "sp1-a";
export var pIndexLessons__img = "sp10a";
export var pIndexLessons__catch1 = "sp11a";
export var pIndexLessons__system = "sp12a";
export var pIndexLessons__textSmall = "sp13a";
export var pIndexLessons__systemText = "sp14a";
export var pIndexLessons__lessons = "sp15a";
export var animationMarquee = "sp16a";